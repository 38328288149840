import axios from 'axios'
const URL_API = '/api'
import LogServices from '@/assets/js/services/LogServices'

const getAll = async () => {
    try {
        const { data } = await axios.get(`${URL_API}/Vaga`)
        return data;
    } catch (e) {
        LogServices.logErro(e, 'getAllVagas')
        throw (e)
    }
}

const getById = async (id) => {
    try {
        const { data } = await axios.get(`${URL_API}/Vaga/${id}`)
        return data
    } catch (e) {
        LogServices.logErro(e, 'getByIdVaga')
        throw (e)
    }
}

const getPagina = async ({ nomeFiltrado, ordenacao = 'DESC', paginaAtual, tamanhoPagina }) => {
    try {
        const { data } = await axios.get(`${URL_API}/Vaga/pagina`, {
            params: {
                'Nome': nomeFiltrado,
                'Ordenacao': ordenacao,
                'PaginaAtual': paginaAtual,
                'TamanhoPagina': tamanhoPagina,
            }
        })
        return data
    } catch (e) {
        console.log('erro getPaginaVagas', e)
        throw (e)
    }
}

const post = async (vaga) => {
    try {
        const { data } = await axios.post(`${URL_API}/Vaga`, vaga)
        return data
    } catch (e) {
        LogServices.logErro(e, 'postVaga')
        throw (e)
    }
}

const update = async (vaga) => {
    try {
        const { data } = await axios.put(`${URL_API}/Vaga/${vaga.Id}`, vaga)
        return data
    } catch (e) {
        LogServices.logErro(e, 'updateVaga')
        throw (e)
    }
}

const aprovarCandidato = async (vagaId) => {
    try {
        await axios.put(`${URL_API}/Vaga/${vagaId}/aprovar`)
    } catch (e) {
        LogServices.logErro(e, 'aprovarVaga')
        throw (e)
    }
}

const deleteById = async (id) => {
    try {
        const { data } = await axios.delete(`${URL_API}/Vaga/${id}`)
        return data
    } catch (e) {
        LogServices.logErro(e, 'deleteVaga')
        throw (e)
    }
}

export default {
    getAll,
    getById,
    getPagina,
    post,
    update,
    aprovarCandidato,
    deleteById
}